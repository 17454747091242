<template lang="pug">
input-group(
  :label="label"
  :keys="keysWithValue"
  :allKeysToggled="allKeysToggled"
  :placeholders="placeholders"
  :editMobile="editMobile"
  @all-keys-change="$emit('all-keys-change', $event)"
  @input="$emit('input', $event)"
)
</template>
<script>
  import InputGroup from '../InputGroup/InputGroup.vue';

  export default {
    components: {
      InputGroup,
    },
    props: {
      label: {
        type: String,
      },
      editMobile: {
        type: Boolean,
        default: false,
      },
      keysWithValue: {
        type: Array,
        required: true,
      },
      allKeysToggled: {
        type: Boolean,
        default: false,
      },
      placeholders: {
        type: Array,
      },
    },
  };
</script>
