<template lang="pug">
Dropdown#fontFamily(
  :label="$t('fontFamily')"
  v-model="fontFamily"
  :items="fonts"
  :layout="layout"
  @input="$emit('input', $event)"
)
  template(#afterDropdown v-if="manage")
    OmButton(iconOnly icon="cog" @click="$modal.show('font-management')")
</template>
<script>
  import { getMostUsedFonts } from '@/editor/util/getMostUsedFonts';
  import { mapGetters, mapState } from 'vuex';
  import Dropdown from '../Dropdown/Dropdown.vue';

  export default {
    components: {
      Dropdown,
    },
    props: {
      value: {
        type: String,
      },
      layout: {
        type: String,
        default: 'col',
        options: ['row', 'col'],
        validator: (value) => {
          return ['row', 'col'].includes(value);
        },
      },
      manage: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(['themeKit']),
      ...mapGetters(['installedFonts']),
      ...mapGetters({
        userId: 'databaseId',
      }),
      selectedFont: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      fonts() {
        return getMostUsedFonts(this.userId, this.installedFonts).map(({ value, label }) => ({
          value,
          text: label,
        }));
      },
      fontFamily: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
  };
</script>
