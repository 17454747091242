import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      expiresInUnits: [
        { text: this.$t('discountPane.automatic.days'), value: 'day' },
        { text: this.$t('discountPane.automatic.hours'), value: 'hour' },
        { text: this.$t('discountPane.automatic.minutes'), value: 'minute' },
      ],
    };
  },
  computed: {
    ...mapState(['selectedElement', 'validationError', 'campaign']),
    ...mapGetters(['isActiveShopifyDomain']),
    isActiveShopify() {
      return this.isActiveShopifyDomain(this.campaign.domain);
    },
    coupon() {
      return this.selectedElement.data.coupon;
    },
    showAutoRedeem() {
      return (
        this.coupon.type !== 'followup' &&
        this.isActiveShopify &&
        (this.coupon.type !== 'fixed' ||
          (this.coupon.type === 'fixed' &&
            this.shopifyFixedCoupon &&
            !this.loadingFixedCoupon &&
            this.coupon.fixedCoupon))
      );
    },
  },
};
